import React, { useEffect,useState } from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";
import SEO from "./components/seo";

const HelpCenter = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])

    const [searchQuery, setSearchQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const data = [
        { title: "Tasks", section: "Tasks", description: "Learn how to create and manage tasks effectively." },
        { title: "Teams", section: "Teams", description: "Explore team management and collaboration tools." },
        { title: "Meetings", section: "Meetings", description: "Discover how to schedule and manage meetings." },
        { title: "Manage Your Account", section: "ManageYourAccount", description: "Step-by-step guide to manage your account." },
        { title: "Integrations", section: "Integrations", description: "Learn how to connect favorite meeting platforms." },
        { title: "Analytics", section: "Analytics", description: "Access organizational-level analytics for insights." },
        {
            title:"Onboarding",
            section:"Onboarding",
            description:"Learn how to onboard your team effectively."
        },
        {
   title:"Users",
    section:"Users",
    description:"Manage users and permissions in Uniteam."
        },
        {
            title:"Kwikbot",
            section:"Kwikbot",
            description:"Get started with Kwikbot and automate tasks."
        }
    ];

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query) {
            const filteredSuggestions = data.filter(item =>
                item.title.toLowerCase().includes(query.toLowerCase()) ||
                item.description.toLowerCase().includes(query.toLowerCase())

            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    return (
        <div className="publicpage">
            <SEO
                title="Uniteam Help Center | Support for AI-Driven Collaboration Tools"
                description="Access the Uniteam Help Center for comprehensive guides, FAQs, and support resources to effectively utilize our AI-powered task management and team collaboration platform."
                keywords="Uniteam help center, AI collaboration support, task management assistance, team collaboration guides, user support resources"
            />
            <Header />



            {/* Top Banner */}
            <section className="help-banner-container">
                <div className='banner-container-detals'>
                    <h1>How can we help?</h1>
                   
                    {/* <div className="search-box">
                       <img src='images/icons/searchicon.svg' className='search-i-help' />
                        <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                         {suggestions.length > 0 && (
                            <div className="suggestions-list">
                                {suggestions.map((item, index) => (
                                    <Link
                                        key={index}
                                        to="/helpcenter/details"
                                        state={{
                                            activeSection: item.section,
                                            activeTitle: item.title
                                        }}
                                        className="suggestion-item"
                                    >
                                        {item.title}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div> */}

<div className="search-box" style={{ position: 'relative' }}>
    <img src="images/icons/searchicon.svg" className="search-i-help" alt="search-icon" />
    <input
        type="text"
        className="form-control search-box"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
    />
    {suggestions.length > 0 && (
        <div className="suggestions-list">
            {suggestions.map((item, index) => (
                <Link
                    key={index}
                    to="/helpcenter/details"
                    state={{
                        activeSection: item.section,
                        activeTitle: item.title,
                    }}
                    className="suggestion-item"
                >
                    {item.title} - {item.description}
                </Link>
            ))}
        </div>
    )}
</div>



                </div>
            </section>
            <div className='innercontainer'>

                {/* <!-- Innovation Section --> */}
                <section className="innovation-section mt-100">

                    <div className="row d-flex flex-wrap align-items-stretch">
                        <div className="col-md-6 col-sm-12 mb-3">
                            <h2 className="mb-3 hcheading">
                                New to<br />  Uniteam?
                            </h2>
                            <p className='comon-para'>Start your journey here with beginner-friendly resources that help you and your team work smarter.</p>
                            <Link to="https://app.uniteam.ai/register"  
                                                    
                             class="btn btn-commn btn-lg"> Get Started</Link>

                        </div>


                        <div className="col-md-6 col-sm-12 ">

                            <img src="../images/about-us/innovation-core2.png" className="img-fluid img-wid100" alt="" />

                        </div>
                    </div>

                </section>

                <section class="impowering-section help-idty mt-100">
                    <div class="text-center">
                        <h2>Get your questions<br /> answered</h2>

                    </div>
                    <div className="container-fluid">
                        <div className="row mt-80">
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                <Link to="/helpcenter/details"  
                            state={{
                              activeSection: "Tasks",
                              activeTitle: "Tasks"
                            }}    >
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Tasks </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        In Uniteam, creating, managing, and tracking tasks is easy and designed to keep your team organized. Here’s a friendly, step-by-step guide to help you navigate tasks effectively.
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                <Link to="/helpcenter/details"  
                            state={{
                              activeSection: "Teams",
                              activeTitle: "Teams"
                            }}    >
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Teams </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        When you first sign up on Uniteam as the Admin, you’ll be asked to select a few teams during onboarding. These teams will automatically appear in the Teams section. 
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                <Link to="/helpcenter/details"  
                            state={{
                              activeSection: "Meetings",
                              activeTitle: "Meetings"
                            }}    >
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Meetings </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        Scheduling and managing meetings in Uniteam is simple and designed to fit your team’s needs. Whether it's a one-time meeting or a recurring event, Uniteam has got you covered. 
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                <Link to="/helpcenter/details"  
                            state={{
                              activeSection: "ManageYourAccount",
                              activeTitle: "Manage Your Account"
                            }}    >
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Manage Your Account </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        Managing your account in Uniteam is super easy, and you’re always in control of your personal details. Let’s walk through how to update your information step by step!
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                <Link to="/helpcenter/details"  
                            state={{
                              activeSection: "Integrations",
                              activeTitle: "Integrations"
                            }}    >
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Integrations </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        Uniteam makes connecting your favorite meeting platforms and calendars a breeze! Whether you’re team Google Meet, a Zoom master, or a Microsoft Teams pro, we’ve got you covered. 
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                <Link to="/helpcenter/details"  
                            state={{
                              activeSection: "Analytics",
                              activeTitle: "Analytics"
                            }}    >
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Analytics </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        As an Organization or Company Admin, you have access to organizational-level analytics to gain insights into overall productivity and performance.
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Customer First Approach --> */}
                <section class="customer-approach mt-100" 
                style={{ display: 'none' }}>
                
                    <div class="row my-4">
                        <div class="col-md-6 col-sm-12">
                            <h2 class="mb-40 ">  Learn Uniteam <br />in minutes</h2>

                            <div class="video-list">
                                <a href="#" class="d-flex justify-content-between">
                                    <span><i className='me-1'><img src='images/home/play-icon.svg' /> </i> Plan your day with Uniteam</span>
                                    <span>2:15</span>
                                </a>
                                <a href="#" class="d-flex justify-content-between">
                                    <span> <i className='me-1'><img src='images/home/play-icon.svg' /> </i>Find anything with advanced search</span>
                                    <span>2:15</span>
                                </a>
                                <a href="#" class="d-flex justify-content-between">
                                    <span> <i className='me-1'><img src='images/home/play-icon.svg' /> </i>Time tracking</span>
                                    <span>2:15</span>
                                </a>
                                <a href="#" class="d-flex justify-content-between">
                                    <span><i className='me-1'><img src='images/home/play-icon.svg' /> </i>Setup your profile & do not disturb</span>
                                    <span>2:15</span>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <img src="../images/home/learn-uniteam.png" class="img-fluid img-wid100" alt="Customer handshake" />

                        </div>

                    </div>


                </section>

                {/* <!-- Team Section --> */}
                <section class="team-Section" style={{ display: 'none' }}>
                    <div class="text-center">
                        <h2 className='text-center'>Meet The Team</h2>
                        <p className='mb-40'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <p className='pt-10'> &nbsp;</p>
                    </div>
                    <div class="row my-4">
                        <div class="col-md-4 team-card">
                            <img src="../images/about-us/ceo.svg" class="img-fluid img1" alt="John Doe" />
                            <h4>John Doe</h4>
                            <p className='designation'>CEO & Founder</p>
                        </div>
                        <div class="col-md-4 team-card">
                            <img src="../images/about-us/ceo.svg" class="img-fluid img1" alt="Mary Garcia" />
                            <h4>Mary Garcia</h4>
                            <p className='designation'>Co-Founder</p>
                        </div>
                        <div class="col-md-4 team-card">
                            <img src="../images/about-us/ceo.svg" class="img-fluid img1" alt="Naomi Lamb" />
                            <h4>Naomi Lamb</h4>
                            <p className='designation'>Operations Manager</p>
                        </div>
                    </div>

                </section>

            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default HelpCenter