import React, { useEffect } from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";
import SEO from "./components/seo";

const Features = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])
    return (
        <div className="publicpage">
            <SEO
                title=''
                description=''
                keywords=''
            />
            <Header />
            <div className='features-page'>
                <div className="header-container">
                    <div className="">
                        <div className='breadcrumb-container'>
                            <Link to="/">Features {'>'} </Link>
                            <spna>Team</spna>
                        </div>
                        <h1 className="">Everything Your Team Needs to Work Smarter – All in One Place</h1>
                        <p className="mb-4">
                            Welcome to Uniteam's Task Management—your ultimate solution to organizing work<br />
                            effortlessly and boosting team productivity.
                        </p>
                        <div className='features-page-btn-container'>
                        <Link to="https://app.uniteam.ai/register"  className="btn  get-started">
                                Get Started
                            </Link>
                            <Link to="/book-a-demo"  className="btn book-demo">
                                Book a Demo
                            </Link>
                        </div>

                    </div>
                   
                </div>
                <div className='help-you'>
                        <p>Here's how we can help you</p>
                    </div>
                {/* Features Section */}
                <section className="innercontainer feature-container">

                    <div className="row">
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>All Your Tasks, One Clean View</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Effortless Task Creation & Delegation</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Team Leader Advantages</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Never Miss a Beat</span>
                            </div>

                        </div>
                        {/* <div className="col-md-4 ">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Integrated Meeting Actions</span>
                            </div>

                        </div>
                        <div className="col-md-4 ">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="" />
                                <span>Boost Productivity with AI Assistance</span>
                            </div>

                        </div> */}
                    </div>

                </section>
                {/* Section: All Tasks in One View */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>All Your Tasks, One <br />Clean View</h2>
                            <p>
                                With Uniteam, you get a single, clutter-free dashboard that displays all your tasks in one place.
                                Tasks are intelligently categorized into buckets such as Overdue, Today,
                                Tomorrow, and This Week, making it easy to manage your workload at a glance.
                                For those who prefer a different perspective, you can switch to date-wise views
                                to better plan ahead and stay on top of your schedule.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-1.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Effortless Task Creation */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Effortless Task Creation &amp; <br />Delegation</h2>
                            <p>
                                Uniteam simplifies task management by allowing users to quickly add tasks for
                                themselves without leaving their current workflow. Delegating tasks is
                                just as easy, enabling users to assign responsibilities to team members
                                with a few clicks. What makes Uniteam truly innovative is its GenAI-powered
                                assistant, which allows users to create or assign tasks using voice or text
                                commands, making task management as easy as speaking or typing a simple instruction.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-2.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Team Leader Advantages */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Team Leader Advantages</h2>
                            <p>
                                For team leaders, Uniteam provides a real-time overview of all team members' tasks,
                                offering transparency and control over ongoing work. Every morning,
                                leaders receive a digest email that summarizes the tasks completed by
                                their team the previous day, significantly reducing the need for frequent
                                status meetings. This ensures leaders can easily monitor progress, ensuring
                                deadlines are met while maintaining balanced workloads across the team.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-3.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Never Miss a Beat */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Never Miss a Beat</h2>
                            <p>
                                Uniteam helps users stay on track by providing automated reminders and
                                clear deadlines, which makes prioritizing tasks more straightforward.
                                With transparent task statuses, the need for constant follow-ups is minimized,
                                as team members can easily track their progress and understand what’s due next,
                                streamlining communication and focus.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-4.png"
                                className="img-fluid  img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>

                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Integrated Meeting Actions</h2>
                            <p>
                                After meetings, Uniteam enables the creation of action items that are automatically
                                added to the task list of the relevant team members. As tasks are checked off and
                                completed, the meeting reports are updated automatically, ensuring that everyone stays
                                informed without any manual effort, keeping post-meeting actions organized and on track.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-4.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Boost Productivity with AI */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Boost Productivity with AI Assistance</h2>
                            <p>
                                Uniteam’s GenAI-powered assistant not only helps create and assign tasks
                                but also simplifies routine task management. The assistant can provide
                                instant status reports with just a simple voice or text command, allowing
                                team members and leaders to quickly gauge progress without interrupting workflows.
                                This innovation lets users focus on more strategic tasks while the AI handles
                                the busywork.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-1.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                <section className="testimonial-section innercontainer mt-100">
                    {/* Company Logo */}
                    <div className="company-logo">
                        <img
                            src="images/features/Amazon_logo.svg"
                            alt="Amazon Logo" 
                        />
                    </div>
                    {/* Testimonial Text */}
                    <div className="testimonial-text">
                        <p>
                        “Uniteam has revolutionized our task management process. The AI features
                            are incredibly helpful! Synthium has revolutionized our task management
                            process. The AI features are incredibly helpful!”
                            </p>
                    </div>
                    {/* Author Info */}
                    <div className="author-info">
                        {/* Author Image */}
                        <div className="author-img">
                            <img src="/images/features/sample-profile.png" alt="Jonathan Doe" />
                        </div>
                        {/* Author Details */}
                        <div className="author-details">
                            <h4>Jonathan Doe</h4>
                            <span>Design Director, Amazon</span>
                        </div>
                    </div>
                    </section>

            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default Features