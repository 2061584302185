import React, { useEffect } from "react";
import ContactUsSection from "./components/ContactUsSection";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NewFooter from "./components/newFooter";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SEO from "./components/seo";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("scrollTo", "");
  }, []);
  return (
    <div className="publicpage">
       <SEO
                title='About Uniteam: Innovating AI-Driven Team Collaboration Solutions'
                description="Learn about Uniteam's mission to revolutionize task management and team collaboration through AI-driven solutions that enhance productivity and employee engagement."
                keywords="Uniteam, about us, AI-driven solutions, team collaboration, task management, productivity enhancement, employee engagement"
            />
      <Header />
      <div className="about-page">
        {/* Top Banner */}
        <section className="about-top-section">
          <div className="row">
            <div className="">
              <img
                src="../images/about-us/about-bg-logo.svg"
                alt=""
                className="about-top-right-logo"
              />
            </div>
            <div className="col-md-12 col-sm-12 ">
              <div className="top-banner-text">
                <h1>
                We're on a mission to  <br />
                simplify work, <br /> foster engagement,
                  <br />  and boost {" "}
                  <span className="">
                  productivity <br />
                    through AI
                  </span>
                </h1>
              
              </div>
            </div>
          </div>
        </section>
        <section class="vision-section">
          <div class="row">
            <div class="col-md-6">
              <img
                src="../images/about-us/our-vision-1.png"
                class="img-fluid img1"
                alt=""
              />

              <div className="vision-box">
                <h4> Our Vision </h4>
                <p>
                We envision a world where work feels seamless, creativity thrives, and productivity reaches its peak. Uniteam is here to make that a reality by equipping organizations with tools that reduce chaos, bring clarity, and empower teams to focus on what truly matters.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <img
                src="../images/about-us/our-vision-2.png"
                class="img-fluid img2"
                alt=""
              />
            </div>
          </div>
        </section>

        {/* <!-- Innovation Section --> */}
        <section className="innovation-section">
          <div className="row d-flex flex-wrap align-items-stretch">
            <div className="col-md-6 col-sm-12">
              <h2 className="mb-3">
              Built for Simplicity,
                <br /> Driven by Innovation
               
              </h2>
              <img
                src="../images/about-us/innovation-core1.png"
                className="img-fluid img1"
                alt=""
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <img
                src="../images/about-us/innovation-core2.png"
                className="img-fluid img2"
                alt=""
              />
              <p className="para">
              Founded by Mihir an AI researcher from IIT Kharagpur, and Manish a seasoned tech entrepreneur, Uniteam is the brainchild of visionary minds who are passionate about making work better for everyone. From reducing endless follow-ups to eliminating unnecessary meetings, our innovative AI solutions pave the way for smarter, stress-free collaboration.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- Empowering Teams --> */}
        <section class="impowering-section">
          <div class="text-center">
            <h2>
              Empowering
              <br /> Teams of All Sizes
            </h2>
            <p class="para">
            Whether you're a nimble startup or a global powerhouse, Uniteam is built to adapt to your unique needs. From organizing personal to-dos to managing large-scale team projects, our platform ensures that every task is tackled efficiently and every team member stays connected.
            </p>
          </div>
          <div class="row my-4">
            <div class="col-md-12">
              <img
                src="../images/about-us/all-size-emp-group.png"
                class="img-fluid img1"
                alt="Small teams"
              />
            </div>
          </div>
        </section>

        {/* <!-- Customer First Approach --> */}
        <section class="customer-approach">
          <div class="row my-4">
            <div class="col-md-6 col-sm-12">
              <h2 class="mb-40">Customer-First Approach</h2>
            </div>
            <div class="col-md-6">
              <p>
              At Uniteam, user experience is at the heart of everything we do. We listen closely to feedback, iterate constantly, and ensure our support team is always there to guide you. Because when you succeed, so do we.
              </p>
            </div>
            <div class="col-md-12 col-sm-12">
              <img
                src="../images/about-us/customer-first-approach.png"
                class="img-fluid img1"
                alt="Customer handshake"
              />
            </div>
          </div>
        </section>

        {/* <!-- Team Section --> */}
        <section class="team-Section" style={{ display: "none" }}>
          <div class="text-center">
            <h2 className="text-center">Meet The Team</h2>
            <p className="mb-40">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <p className="pt-10"> &nbsp;</p>
          </div>
          <div class="row my-4">
            <div class="col-md-4 team-card">
              <img
                src="../images/about-us/ceo.svg"
                class="img-fluid img1"
                alt="John Doe"
              />
              <h4>John Doe</h4>
              <p className="designation">CEO & Founder</p>
            </div>
            <div class="col-md-4 team-card">
              <img
                src="../images/about-us/ceo.svg"
                class="img-fluid img1"
                alt="Mary Garcia"
              />
              <h4>Mary Garcia</h4>
              <p className="designation">Co-Founder</p>
            </div>
            <div class="col-md-4 team-card">
              <img
                src="../images/about-us/ceo.svg"
                class="img-fluid img1"
                alt="Naomi Lamb"
              />
              <h4>Naomi Lamb</h4>
              <p className="designation">Operations Manager</p>
            </div>
          </div>
        </section>
      </div>
      {/* <Footer /> */}
      <NewFooter />
    </div>
  );
};

export default AboutUs;
