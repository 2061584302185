import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Tasks() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Managing Tasks in Uniteam</h3>
                <p className='pai'>
                    In Uniteam, creating, managing, and tracking tasks is easy and designed to keep your team organized. Here’s a friendly, step-by-step guide to help you navigate tasks effectively.

                </p>
                <p className='steps'>Step 1: Access the Tasks Dashboard


                        </p>
                <p className='pai'>
                    Once you’re logged into Uniteam, click on the Tasks option in the left-hand menu. This brings you to the tasks dashboard, where all your tasks are categorized for easy management.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/task1.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 2: Explore the Task Categories
                        </p>
                <p className='pai'>
                    <b>  Tasks are organized into two main sections:</b>

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            Live tasks, which are split into:
                        </p>

                    </li>

                </ul>
                <ul className='inner-ul'>
                    <li>Overdue </li>
                    <li>Today </li>
                    <li>Tomorrow </li>
                    <li>This Week </li>
                    <li>This Month </li>
                    <li>Later </li>
                </ul>
               
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        Done tasks, which are completed and grouped into:

                        </p>

                    </li>

                </ul>
                <ul className='inner-ul'>
                    <li>This Month
                    </li>
                    <li>Other Past Months </li>
                   
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/task1.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 3: Create a New Task


                        </p>
                <p className='pai'>
                To add a new task, click the + New Task button at the top right. A pop-up will guide you through filling out the following details:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Task Name:</b> Provide a clear title.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Task Note:</b> Include any important instructions.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Start Date & End Date:</b> Set the task's timeframe
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Priority:</b> Choose between High or Normal.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Attachment:</b> Upload any relevant files (documents or images)</p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Assign to: </b>Search by name or select from the list. Multiple people can be assigned, and if you don’t select anyone, the task will be assigned to you.
                        </p>
                    </li>

                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/task2.jpg" alt="Hero Image" />
                    </div>
                </div>
                <p className='pai'>
                Once everything is set, click Create Task.

                </p>
                <p className='steps'>Step 4: KwikBot - Your AI Task Assistant
                        </p>
                <p className='pai'>
                Want to make task creation even easier? Let KwikBot handle it! Simply provide all the details like task name, dates, priority, and assignees in one go, and KwikBot will do the rest—instantly creating and assigning the task based on your input.

                </p>
                <p className='pai'>
                You can also request task status reports from KwikBot. For example, ask:

                </p>

                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>“What tasks did John complete yesterday?”
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>“What is the Tech team working on today?”
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>“What tasks are overdue in my department?”

                        </p>
                    </li>
                    
                </ul>
                <p className='pai'>
                KwikBot will provide real-time updates, keeping you informed without the need to manually check.
                    
                     </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/task3.png" alt="Hero Image" />
                    </div>
                </div>

                <p className='steps'>Step 5: Edit an Existing Task
                        </p>
                <p className='pai'>
                Need to make changes to a task? Just click on the task name in any category (Live or Done), and you can edit details like the task name, date, priority, or assignees. After making the changes, save them to update the task.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/task4.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 6: Use the Calendar View

                        </p>
                <p className='pai'>
                To get a visual overview of your tasks, you can switch to the Calendar View. From the tasks dashboard, click the Calendar Icon in the upper-right corner. This will display all your tasks on a calendar, making it easier to see deadlines and manage your schedule at a glance.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/task5.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 7: Complete a Task


                        </p>
                <p className='pai'>
                When a task is done, click the radio button next to the task name. This will mark it as complete, and the task will automatically move to the Done section.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/task6.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='pai'>
                With Uniteam’s easy task management, you can create, edit, and even view tasks on a calendar to stay organized and stress-free. Happy tasking!

                </p>
            </div >
        </>
    );

}

export default Tasks;
