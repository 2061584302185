import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyEmbed = () => {
  return (
    <div style={{ minWidth: '320px', height: '720px',backgroundColor: '#1d4f3a',padding: '10px',borderRadius: '8px', marginTop: '-80px'}}>
      <InlineWidget
        url="https://calendly.com/uniteamai/30min?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=ffffff&primary_color=1d4f3a"
        styles={{ height: '100%' }}
      />
    </div>
  );
};

export default CalendlyEmbed;
