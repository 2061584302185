import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CalendlyEmbed from "./components/CalendlyEmbed";

function BookADemo() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="onboarding login">
        <div class="d-flex justify-content-between onboarding-top-header align-items-center">
          <div class="logo mt-0">
            <Link class="navbar-brand mt-0 pt-0"
              to="/"
             >
              <img
                alt="image"
                src="images/icons-v2/uniteam-Logo-white-new.svg"
                width="150px"
              />
            </Link>
          </div>
          <div class="closebtn">
            
            <Link onClick={() => navigate(-1)}>
              <img alt="uniteamImage" src="/images/icons/onbording-cross.svg" />
            </Link>
          </div>
        </div>

        <div className="login-right-pnl">
          <CalendlyEmbed/>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default BookADemo;
