import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import FrequentlyAskQues from '../components/FrequentlyAskQues'
import Header from '../components/Header'
import SEO from '../components/seo';


const FeatureProject = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">
            <SEO
                title=''
                description=''
                keywords=''
            />
            <Header />
            {/* Top Banner */}
            <section className="top_banner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_flex">
                                <div className="text_Side">
                                    <div className="heading">
                                        <h4>Projects</h4>
                                        <h1>
                                            All your tools for project tracking under one roof
                                        </h1>
                                        <p>
                                            Uniteam helps keep information clear and visible, so even the tiniest deliverables aren’t missed.
                                        </p>
                                        <div className="wrap-btn mt-30"><a className="btn btn-primary btn-lg" href="#/register">Give Uniteam a Try</a></div>
                                    </div>
                                </div>
                                <div className="img_side">
                                    <img src="images/features/fd_project.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Feature */}
            <div className="team-sec Features" id="Features">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">

                            {/* 1 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Project Planning
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                Quickly plan your project by creating milestones and tasks under each milestone.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-text pr mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Project Team
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                Quickly add the people who need to work on your project and assign
                                                the task to them.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-img">
                                            <img src="images/meeting-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* 3 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Project Documents
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                All your project-related documents are in one place. You can organize your project documents such as project specifications, design, guidelines, etc. by creating folder structure.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 4 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-text pr mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Easy Progress Updates
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                The smartest thing about the Uniteam is that team members do not need to come to the project section to update the progress. They will get the project-related tasks to their master task list.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-img">
                                            <img src="images/meeting-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Frequently Ask Question */}
            <FrequentlyAskQues />
            {/* Contact Us Section */}
            <ContactUsSection />

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default FeatureProject;